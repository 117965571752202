
function Cloud() {

	return (

      <p className="orange cloud">

<span className="hidden">.</span>                    __-=;.__                  <br></br>      
<span className="hidden">.</span>                  oG********/\,               <br></br>  
<span className="hidden">.</span>           __==__//******Kl****/\.            <br></br>   
<span className="hidden">.</span>         .@@#******####*********/  <span className="lightorange">._=-__    </span> <br></br>   
<span className="hidden">.</span>      _=/***%**%$**-0**&&&*****/  <span className="lightorange">/6*O8**\   </span> <br></br>     
<span className="hidden">.</span>    */****FF@;***//L(///[/##P'   <span className="lightorange"> \@##%[0?#\ </span> <br></br>    
<span className="hidden">.</span>   /%&65*--==_______________     <span className="lightorange"> _____==-*/ </span> <br></br>   
<span className="hidden">.</span>   *Q__@#/@#idobackendtoodw/@\  <span className="lightorange">/@[***86LO'  </span>

     </p>
	)
}

export default Cloud;
